import React from 'react'

import { useParams } from 'react-router-dom'
import { useUser } from 'contexts'
import { Board, PlayersStatus, PlayerHand, LastPlayed } from './components'
import { GameProvider } from './game-context'
import { scumGameService } from 'services'
import { Alert } from 'react-bootstrap'
import { Scum } from './view-models'
import { RoundPlayList } from './components/RoundPlayList'

export const GameUI = () => {
	const { id } = useParams()
	const user = useUser()
	const result = scumGameService.useGet(id)

	if (result.loading) {
		return <>Loading game...</>
	}

	if (!result.data) {
		return <Alert variant='danger'>The game data could not be found.</Alert>
	}

	if (!user) {
		return <Alert variant='danger'>Your user account could not be found.</Alert>
	}

	return (
		<GameProvider value={new Scum(id, user, result.data)}>
			<Board>
				<PlayersStatus />
				<PlayerHand />
				<RoundPlayList />
				<LastPlayed />
			</Board>
		</GameProvider>
	)
}
