import { ScumPlayer } from './player'
import { AppUser } from 'models'
import { ScumJson, ScumStatus } from 'models/games/scum'
import { ArrayUtils } from 'utils'
import { ScumRound } from './round'
import { RoundManager } from '../round-manager'
import { scumGameService } from 'services'

export class Scum {
	round: ScumRound
	lastRound?: ScumRound
	players: Array<ScumPlayer>
	currentTurnPlayer: ScumPlayer
	status: ScumStatus
	gameCount: number

	get yourPlayer() {
		return this.players.find((p) => p.userId === this.user.id) as ScumPlayer
	}

	constructor(public id: string, public user: AppUser, model: ScumJson) {
		this.round = new ScumRound(new RoundManager(user.id, id, scumGameService), model.round)
		this.lastRound = model.lastRound
			? new ScumRound(new RoundManager(user.id, id, scumGameService), model.lastRound)
			: undefined
		this.players = ArrayUtils.mapFromFirebaseArray(
			model.players,
			(id, p) =>
				new ScumPlayer(
					id,
					model.players,
					p,
					p.userId === model.currentTurnUserId,
					this.round.lastValue,
					this.round.playSize,
				),
		).orderBy((p) => p.ordinal)
		this.gameCount = model.gameCount
		this.currentTurnPlayer = this.players.find(
			(p) => p.userId === model.currentTurnUserId,
		) as ScumPlayer
		this.status = model.status
	}
}
