import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { useGame } from '../game-context'
import { Card } from './Card'
import { PlaySize } from './PlaySize'
import { CardGroup } from '../view-models/card-group'
import { usePassCheck, useYouPlayedLastCheck, useCheckNextOrdinal } from '../hooks'
import { PlayerPass } from './PlayerPass'
import { palette } from 'assets/palette'
import { confirmation } from 'components'
import { Sounds } from 'utils'

const { card } = palette

const Component = (props: { className?: string }) => {
	const { yourPlayer: player, round } = useGame()
	usePassCheck()
	useYouPlayedLastCheck()
	useCheckNextOrdinal()
	Sounds.use(player.settings.enableSounds && player.isYourTurn, 'misc/doorbell.wav')

	if (!player || player.cardGroups.length === 0) {
		return <></>
	}

	const onPlayGroup = async (group: CardGroup) => {
		if (group.playable) {
			// if enabled, the group is a multiple AND there are more cards of that value than what's in the group
			if (
				player.settings.enableBreakupMultipleConfirmation &&
				group.cards.length < player.cards.count((p) => p.value === group.cards[0].value)
			) {
				if (
					!(await confirmation(
						`Are you sure you want to break up your ${group.cards[0].face}s?`,
					))
				) {
					return
				}
			}

			const result = await round.manager.playCardGroup(group)
			if (!result.success) {
				alert(result.errors?.join('\n'))
			}
		}
	}

	return (
		<div className={classNames(props.className, { passed: player.passed })}>
			<PlaySize />
			<PlayerPass />
			<div className='cards'>
				{player.cardGroups.map((group, index) => (
					<div
						onClick={() => onPlayGroup(group)}
						key={index}
						className={classNames('card-group', {
							playable: group.playable,
							unplayable: group.playable === false,
						})}
					>
						{group.cards.map((card) => (
							<Card key={card.id} card={card} />
						))}
					</div>
				))}
			</div>
		</div>
	)
}

export const PlayerHand = styled(Component)`
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	text-align: center;

	.cards {
		text-align: center;

		.card-group {
			position: relative;
			display: inline-flex;
			margin-left: clamp(
				-${card.clampWidth.maxPixels * card.overlapPercent}px,
				-${card.clampWidth.viewWidth * card.overlapPercent}vw,
				-${card.clampWidth.minPixels * card.overlapPercent}px
			);
			transition: all ease-in-out 0.25s;
			border-radius: ${card.borderRadius};

			&.playable {
				cursor: pointer;

				&:hover {
					transform: scale(1.2);
					box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
				}
			}

			&.unplayable {
				${Card} {
					&::after {
						opacity: 0.3;
					}
				}
			}

			&:first-child {
				margin-left: 0;
			}

			${Card} {
				margin-left: clamp(
					-${card.clampWidth.maxPixels * card.overlapPercent}px,
					-${card.clampWidth.viewWidth * card.overlapPercent}vw,
					-${card.clampWidth.minPixels * card.overlapPercent}px
				);

				&:first-child {
					margin-left: 0;
				}

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: black;
					opacity: 0;
					transition: opacity ease-in-out 0.25s;
				}
			}
		}
	}

	&.passed {
		.cards {
			.card-group {
				${Card} {
					&::after {
						opacity: 0.3;
					}
				}
			}
		}
	}

	@media (max-height: 700px) {
		.cards {
			.card-group {
				margin-left: -70px;

				${Card} {
					margin-left: -70px;
					width: 100px !important;
				}
			}
		}
	}
`
