import React from 'react'

export function createContext<T>() {
	const context = React.createContext<T | undefined>(undefined)

	function useContext(allowUndefined?: boolean) {
		const contextValue = React.useContext(context)
		if (!contextValue && !allowUndefined)
			throw new Error('useContext must be inside a Provider with a value.')
		return contextValue as T
	}

	return [useContext, context.Provider] as const
}

export function createContextThatCanBeUndefined<T>() {
	const context = React.createContext<T | undefined>(undefined)

	function useContext() {
		return React.useContext(context)
	}

	return [useContext, context.Provider] as const
}
