import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import { DbResult } from './database-models'
import { useState, useEffect } from 'react'

export interface FirebaseConfigOptions {
	apiKey: string
	authDomain: string
	databaseURL: string
	projectId: string
	storageBucket: string
	messagingSenderId: string
	appId: string
}

export const firebaseConfig: FirebaseConfigOptions = {
	apiKey: 'AIzaSyCTm3Gnj6cRmqeglvNnzWIPhKj7JYucV6o',
	authDomain: 'delfonzo-7ccd3.firebaseapp.com',
	databaseURL: 'https://delfonzo-7ccd3.firebaseio.com',
	projectId: 'delfonzo-7ccd3',
	storageBucket: 'delfonzo-7ccd3.appspot.com',
	messagingSenderId: '503094395518',
	appId: '1:503094395518:web:433ab3ae6485acac638e2f',
}

export const firebaseUiConfig: firebaseui.auth.Config = {
	// Popup sign in flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	callbacks: {
		signInSuccessWithAuthResult: () => false,
	},
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
	],
}

export class FirebaseService {
	app: firebase.app.App

	get auth() {
		return this.app.auth()
	}

	get database() {
		return this.app.database()
	}

	get firestore() {
		return this.app.firestore()
	}

	constructor(config: FirebaseConfigOptions) {
		if (!+process.env.REACT_APP_PRODUCTION) {
			config.databaseURL = 'http://localhost:9000/?ns=delfonzo-7ccd3'
		}

		this.app = firebase.initializeApp(config)

		if (!+process.env.REACT_APP_PRODUCTION) {
			this.firestore.settings({
				host: 'localhost:8080',
				ssl: false,
			})
		}
	}

	useGetAppVersion(): DbResult<string> {
		const [data, setData] = useState<DbResult<string>>({ loading: true })

		useEffect(() => {
			this.database.ref('appInfo/version').once('value', (snapshot) => {
				setData({
					loading: false,
					data: snapshot.val(),
				})
			})
		}, [])

		return data
	}
}

export const firebaseService = new FirebaseService(firebaseConfig)
