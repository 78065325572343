import React from 'react'

import { useFormikForm } from './formik-form.context'
import { Button, ButtonProps } from 'react-bootstrap'

/**
 * A Wrapper Button component that disables itself if it's parent FormikForm is disabled
 * @param props React-UI Button Props
 */
export const FormikButton = ({
	disabled,
	...props
}: ButtonProps & React.PropsWithoutRef<JSX.IntrinsicElements['button']>) => {
	const helper = useFormikForm()
	disabled = disabled || helper.disabled
	return <Button disabled={disabled} {...props} />
}
