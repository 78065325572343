import { useEffect } from 'react'

export type Sound =
	| 'cards/single-card-deal.wav'
	| 'misc/doorbell.wav'
	| 'misc/tada.wav'
	| 'misc/tada-fanfare-a-major.flac'
	| 'misc/tada-fanfare-f-major.flac'
	| 'misc/tada-fanfare-g-major.flac'

export interface SoundOptions {
	/** Delay playing the sound in milliseconds */
	delay: number
}

export class Sounds {
	static use(condition: boolean, sound: Sound, options?: Partial<SoundOptions>) {
		const config: SoundOptions = {
			delay: 0,
			...options,
		}

		useEffect(() => {
			const audio = new Audio(`/audio/${sound}`)
			let timeout: number

			if (condition) {
				timeout = setTimeout(() => {
					audio.play()
				}, config.delay)
			}
			return () => {
				audio.pause()
				clearTimeout(timeout)
			}
		}, [condition, sound, config.delay])
	}
}
