import { CardJson, CardFace, CardSuit, CardColor } from 'games/standard-card-deck'

export class PlayerCard implements CardJson {
	face: CardFace
	suit: CardSuit
	color: CardColor

	get value() {
		switch (this.face) {
			case 'Jack':
				return 11
			case 'Queen':
				return 12
			case 'King':
				return 13
			case 'Ace':
				return 14
			default:
				return parseInt(this.face)
		}
	}

	get imageUrl() {
		return `/images/cards/${this.face}-${this.suit}.png`.toLowerCase()
	}

	get backImageUrl() {
		return `/images/cards/back-${this.color}.png`.toLowerCase()
	}

	constructor(public id: string, { face, suit, color }: CardJson) {
		this.face = face
		this.suit = suit
		this.color = color
	}

	toJSON(): CardJson {
		return {
			face: this.face,
			suit: this.suit,
			color: this.color,
		}
	}
}
