import React, { useState } from 'react'
import { Row, Col, Card, ListGroup, Badge, Alert, Button } from 'react-bootstrap'
import { useParams, Redirect } from 'react-router-dom'

import { lobbyService } from '../services'
import { FlexInfo, DisplayDate, LobbyModal, LobbyModalFormModel } from '../components'
import { useAppUser } from '../contexts'
import classNames from 'classnames'
import { Lobby } from '../models'
import { gameManager } from 'games/scum'

export const LobbyPage = () => {
	const { id } = useParams()
	const { loading, data } = lobbyService.useGet(id)

	if (loading) {
		return <h1 className='display-4'>Loading Lobby...</h1>
	}

	if (!data) {
		return <Redirect to='/' />
	}

	return <LobbyInner id={id} data={data as Lobby} />
}

interface LobbyInnerProps {
	id: string
	data: Lobby
}

const LobbyInner = ({ id, data }: LobbyInnerProps) => {
	const user = useAppUser()
	const joinResult = lobbyService.useJoin(user.id, id)

	if (joinResult.loading) {
		return <h1 className='display-4'>Joining Lobby...</h1>
	}

	if (!joinResult.joined) {
		return <Redirect to='/' />
	}

	if (data.status === 'Started') {
		return <Redirect to={`/game/${id}`} />
	}

	const onStart = () => {
		gameManager.create(id)
	}

	return (
		<>
			<h1 className='display-4'>{data.name}</h1>
			<hr className='my-4' />
			{!data.hasPlayer(data.host.userId) && (
				<Alert variant='danger'>The host has left the game...</Alert>
			)}
			<Row>
				<Col md={6}>
					<Card>
						<Card.Header>Game Info</Card.Header>
						<Card.Body>
							<FlexInfo label='Game'>{data.game}</FlexInfo>
							<FlexInfo label='Host'>{data.host.name}</FlexInfo>
							<FlexInfo label='Created'>
								<DisplayDate value={data.date} />
							</FlexInfo>
							{data.host.userId === user.id && (
								<div className='mt-3 d-flex'>
									<EditLobbyModal id={id} lobby={data} />
									<Button className='ml-auto' variant='primary' onClick={onStart}>
										Start Game
									</Button>
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>
				<Col md={6}>
					<Card>
						<Card.Header className='d-flex'>
							Players{' '}
							<span className='ml-auto'>
								{data.players.length}/{data.maxPlayers}
							</span>
						</Card.Header>
						<ListGroup variant='flush' className='border-top-0'>
							{data.players.map((p) => (
								<ListGroup.Item key={p.userId} className='d-flex'>
									<span
										className={classNames({
											'font-weight-bold': p.userId === user.id,
										})}
									>
										{p.name}
									</span>
									{data.host.userId === p.userId && (
										<Badge className='ml-auto' variant='primary'>
											Host
										</Badge>
									)}
								</ListGroup.Item>
							))}
							{!data.players.length && (
								<ListGroup.Item>Waiting for Players...</ListGroup.Item>
							)}
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</>
	)
}

interface EditLobbyModalProps {
	lobby: Lobby
	id: string
}

const EditLobbyModal = ({ id, lobby }: EditLobbyModalProps) => {
	const [show, setShow] = useState(false)

	const onSave = async (form: LobbyModalFormModel) => {
		if (lobby.players.length > form.maxPlayers) {
			return {
				success: false,
				errors: [
					`There are currently ${lobby.players.length} players, you cannot set your max less than the number of players.`,
				],
			}
		}
		await lobbyService.update(id, form)
		setShow(false)
		return { success: true, errors: undefined }
	}

	return (
		<>
			<Button variant='secondary' onClick={() => setShow(true)}>
				Edit
			</Button>
			<LobbyModal show={show} onHide={() => setShow(false)} onSave={onSave} form={lobby} />
		</>
	)
}
