import React, { ReactNode } from 'react'
import { Form } from 'react-bootstrap'
import { FormikValues, FormikTouched, FormikErrors, FieldInputProps } from 'formik'

import { FormikFormProvider } from './formik-form.context'

/**
 * An interface that looks like the object returned from 'useFormik()'.
 * Sadly the typings for Formik aren't perfect.
 */
export interface FormikHelper<Values extends FormikValues = FormikValues> {
	touched: FormikTouched<Values>
	errors: FormikErrors<Values>
	getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
	handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
	values: FormikValues
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any
}

export interface FormikFormProps<Values extends FormikValues = FormikValues> {
	/** The object returned from the 'useFormik()' hook */
	formik: FormikHelper<Values>
	/** Whether all Formik helper components should be disabled or not. Typically used while submitting forms. */
	disabled?: boolean
	/** React children */
	children?: ReactNode
}

/**
 * FormikForm is a wrapper for the '<form>' element. It also configures a shared context for child Formik components
 * @param props
 */
export const FormikForm = <Values extends FormikValues = FormikValues>({
	children,
	...contextValue
}: FormikFormProps<Values>) => {
	return (
		<FormikFormProvider value={contextValue}>
			<Form onSubmit={contextValue.formik.handleSubmit}>{children}</Form>
		</FormikFormProvider>
	)
}
