import React, { ReactNode, useState } from 'react'
import { Modal, Button, Alert } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ErrorMessage } from '../utils'
import { FormikForm, FormikControl } from './formik'
import { CommandResultLike, GameName } from '../models'

export interface LobbyModalFormModel {
	name: string
	maxPlayers: number
	game: GameName
}

export interface LobbyModalProps {
	show: boolean
	onHide: () => void
	isCreating?: boolean
	onSave: (form: LobbyModalFormModel) => Promise<CommandResultLike>
	form?: LobbyModalFormModel
}

export const LobbyModal = ({ show, onHide, isCreating, onSave, form }: LobbyModalProps) => {
	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{isCreating ? 'Create Lobby' : 'Edit Lobby'}</Modal.Title>
			</Modal.Header>
			<LobbyForm onSave={onSave} form={form}>
				<Modal.Footer>
					<Button type='button' variant='secondary' onClick={onHide}>
						Cancel
					</Button>
					<Button type='submit' variant='primary'>
						{isCreating ? 'Create' : 'Update'}
					</Button>
				</Modal.Footer>
			</LobbyForm>
		</Modal>
	)
}

interface LobbyFormProps {
	children?: ReactNode
	onSave: (form: LobbyModalFormModel) => Promise<CommandResultLike>
	form?: LobbyModalFormModel
}

const LobbyForm = ({ children, onSave, form }: LobbyFormProps) => {
	const [state, setState] = useState<{ submitting: boolean; errors?: string[] }>({
		submitting: false,
	})

	const formik = useFormik<LobbyModalFormModel>({
		initialValues: {
			name: form?.name || '',
			maxPlayers: form?.maxPlayers || 4,
			game: form?.game || 'Scum',
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.required(ErrorMessage.required('Name'))
				.max(50, ErrorMessage.max('Name')),
			maxPlayers: Yup.number()
				.required(ErrorMessage.required('Max Players'))
				.min(3, ErrorMessage.minNumber('Max Players'))
				.max(16, ErrorMessage.maxNumber('Max Players')),
			game: Yup.string().required('Game'),
		}),
		onSubmit: (values) => {
			setState({ submitting: true })
			onSave(values).then(({ success, errors }) => {
				if (success) {
					setState({ submitting: false })
				} else {
					setState({ submitting: false, errors })
				}
			})
		},
	})

	return (
		<FormikForm formik={formik} disabled={state.submitting}>
			<Modal.Body>
				{state.errors && state.errors.length && (
					<Alert variant='danger'>
						{state.errors.map((p) => (
							<div>{p}</div>
						))}
					</Alert>
				)}
				<FormikControl label='Lobby Name' name='name' />
				<FormikControl label='Max Players' name='maxPlayers' type='number' />
				<FormikControl label='Game' name='game' readOnly plaintext />
			</Modal.Body>
			{children}
		</FormikForm>
	)
}
