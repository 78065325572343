import { FirebaseArray } from 'models'

declare global {
	interface Array<T> {
		last(): T | undefined
		orderBy(expression: (item: T) => any): T[]
		shuffle(): T[]
		toFirebaseArray(keySelector?: (item: T) => number | string): FirebaseArray<T>
		count(expression: (item: T) => boolean): number
		clear(): void
		any(expression: (item: T) => boolean): boolean
	}
}

if (!Array.prototype.last) {
	// eslint-disable-next-line
	Array.prototype.last = function () {
		return ArrayUtils.last(this)
	}
}

if (!Array.prototype.orderBy) {
	// eslint-disable-next-line
	Array.prototype.orderBy = function (expression: (item: any) => any) {
		return ArrayUtils.orderBy(this, expression)
	}
}

if (!Array.prototype.shuffle) {
	// eslint-disable-next-line
	Array.prototype.shuffle = function () {
		return ArrayUtils.shuffle(this)
	}
}

if (!Array.prototype.toFirebaseArray) {
	// eslint-disable-next-line
	Array.prototype.toFirebaseArray = function (keySelector?: (item: any) => number | string) {
		return ArrayUtils.toFirebaseArray(this, keySelector)
	}
}

if (!Array.prototype.count) {
	// eslint-disable-next-line
	Array.prototype.count = function (expression: (item: any) => boolean) {
		return ArrayUtils.count(this, expression)
	}
}

if (!Array.prototype.count) {
	// eslint-disable-next-line
	Array.prototype.count = function (expression: (item: any) => boolean) {
		return ArrayUtils.count(this, expression)
	}
}

if (!Array.prototype.clear) {
	// eslint-disable-next-line
	Array.prototype.clear = function () {
		return ArrayUtils.clear(this)
	}
}

if (!Array.prototype.any) {
	// eslint-disable-next-line
	Array.prototype.any = function (expression: (item: any) => boolean) {
		return ArrayUtils.any(this, expression)
	}
}

export class ArrayUtils {
	static fromFirebaseArray<T>(array: FirebaseArray<T>) {
		return Object.values(array || {})
	}
	static mapFromFirebaseArray<TFrom, TTo>(
		array: FirebaseArray<TFrom>,
		map: (key: string, item: TFrom) => TTo,
	) {
		return Object.entries(array || {}).map(([key, item]) => map(key, item))
	}
	static last<T>(array: Array<T>) {
		return array[array.length - 1]
	}
	static orderBy<T>(array: Array<T>, expression: (item: T) => any) {
		return array.sort((a, b) => {
			const aValue = expression(a)
			const bValue = expression(b)
			if (aValue < bValue) {
				return -1
			}
			if (aValue > bValue) {
				return 1
			}
			return 0
		})
	}
	static shuffle<T>(array: Array<T>) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1))
			;[array[i], array[j]] = [array[j], array[i]]
		}
		return array
	}
	static toFirebaseArray<T>(array: Array<T>, keySelector?: (item: T) => number | string) {
		const firebaseArray: FirebaseArray<T> = {}
		for (let i = 0; i < array.length; i++) {
			const item = array[i]
			const key = keySelector ? keySelector(item) : i
			firebaseArray[key] = item
		}
		return firebaseArray
	}
	static count<T>(array: Array<T>, expression: (item: T) => boolean) {
		let count = 0
		array.forEach((p) => {
			if (expression(p)) {
				count++
			}
		})
		return count
	}
	static clear<T>(array: Array<T>) {
		array.splice(0, array.length)
	}
	static any<T>(array: Array<T>, expression: (item: T) => boolean): boolean {
		const found = array.find((p) => expression(p))
		return !!found
	}
}
