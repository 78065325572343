import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import appInfo from 'app-info.json'
import { authService, userService, firebaseService } from 'services'
import { UserProvider, AppUserProvider, useAppUser } from 'contexts'
import { Navigation, PrivateRoute, Splash, VersionMismatch } from 'components'
import { AppUser } from 'models'

import { LoginPage } from './pages/login.page'
import { HomePage } from './pages/home.page'
import { AccountPage } from './pages/account.page'
import { LobbyPage } from './pages/lobby.page'
import { GamePage } from 'pages/game.page'

function App() {
	const info = authService.useFirebaseUser()
	const version = firebaseService.useGetAppVersion()

	return (
		<>
			<Splash show={!info || version.loading} />
			{!version.loading && version.data && (
				<VersionMismatch clientVersion={appInfo.version} latestVersion={version.data} />
			)}
			{info && (
				<AppUserProvider value={info}>
					<InnerApp />
				</AppUserProvider>
			)}
		</>
	)
}

const InnerApp = () => {
	const info = useAppUser()
	const result = userService.useGet(info.authenticated ? info.id : '')

	if (result.loading) {
		return <></>
	}

	let appUser: AppUser | undefined = undefined
	if (info && info.authenticated && result.data) {
		appUser = {
			id: info.id,
			name: result.data.name,
		}
	}

	return (
		<UserProvider value={appUser}>
			<Router>
				<Navigation />
				<Container className='py-3'>
					<Switch>
						<PrivateRoute exact path='/' component={HomePage} />
						<PrivateRoute path='/account' component={AccountPage} />
						<PrivateRoute path='/lobby/:id' component={LobbyPage} />
						<PrivateRoute path='/game/:id' component={GamePage} />
						<Route path='/login' component={LoginPage} />
					</Switch>
				</Container>
			</Router>
		</UserProvider>
	)
}

export default App
