import React from 'react'
import styled from 'styled-components'
import { useGame } from '../game-context'
import { Button } from 'react-bootstrap'
import { GameManager } from '../game-manager'
import { usePlaySizeCheck } from '../hooks'

const Component = (props: { className?: string }) => {
	const { yourPlayer: player, round, currentTurnPlayer, status } = useGame()
	usePlaySizeCheck()
	const itsYourTurn = player.userId === currentTurnPlayer.userId

	if (!itsYourTurn || round.status !== 'Start' || status !== 'Playing Rounds') {
		return <></>
	}

	const playSizes = player.getPlaySizes()

	const onSetPlaySize = (playSize: number) => {
		round.manager.setPlaySize(playSize).then((result) => {
			if (!result.success) {
				alert(result.errors?.join('\n'))
			}
		})
	}

	return (
		<div className={props.className}>
			<div className='play-size-container bg-secondary text-light'>
				<label>Pick your play size</label>
				<ul>
					{playSizes.map((p) => (
						<li key={p}>
							<Button
								onClick={() => onSetPlaySize(p)}
								variant={round.playSize === p ? 'warning' : 'light'}
							>
								{GameManager.toPlaySizeName(p)}
							</Button>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export const PlaySize = styled(Component)`
	margin-bottom: 20px;
	display: flex;
	justify-content: center;

	.play-size-container {
		padding: 20px;
		border-radius: 20px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			justify-content: center;

			li {
				margin: 5px;
			}
		}
	}
`
