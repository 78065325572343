import React from 'react'

export interface DisplayDateProps {
	value: Date
}

const formatter = new Intl.DateTimeFormat('en-US', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	hour12: true,
})

export const DisplayDate = ({ value }: DisplayDateProps) => {
	return <>{formatter.format(value)}</>
}
