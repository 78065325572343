import { useGame } from '../game-context'
import { useEffect } from 'react'

/** If the turn comes back to you because all others have passed, end the round. */
export function useYouPlayedLastCheck() {
	const { round, players, yourPlayer: you, currentTurnPlayer } = useGame()

	const isYourTurn = you.userId === currentTurnPlayer.userId
	const isRoundPlaying = round.status === 'Playing'
	const didAllOthersPass = !players.any((p) => p.userId !== you.userId && !p.passed)
	const youPlayedLast = round.plays.last()?.userId === you.userId

	useEffect(() => {
		if (isYourTurn && isRoundPlaying && didAllOthersPass && youPlayedLast) {
			round.manager.endRound().then((result) => {
				if (!result.success) {
					alert(result.errors?.join('\n'))
				}
			})
		}
	}, [isYourTurn, isRoundPlaying, didAllOthersPass, youPlayedLast, round.manager])
}
