import { ScumRoundStatus, ScumRoundJson, ScumRoundPlayJson } from 'models/games/scum'
import { RoundManager } from '../round-manager'
import { CardFace } from 'games/standard-card-deck'
import { PlayerCard } from './player-card'
import { ArrayUtils } from 'utils'

export class ScumRound {
	status: ScumRoundStatus
	playSize?: number
	plays: Array<ScumRoundPlay>

	get lastValue() {
		return this.plays.last()?.value
	}

	constructor(public manager: RoundManager, { status, playSize, plays }: ScumRoundJson) {
		this.status = status
		this.playSize = playSize
		this.plays = ArrayUtils.mapFromFirebaseArray(
			plays || {},
			(id, p) => new ScumRoundPlay(id, p),
		)
	}
}

export class ScumRoundPlay {
	userId: string
	value: number
	face: CardFace
	cards: Array<PlayerCard>
	random: number

	constructor(public id: string, { userId, value, face, cards, random }: ScumRoundPlayJson) {
		this.userId = userId
		this.value = value
		this.face = face
		this.cards = ArrayUtils.mapFromFirebaseArray(cards, (id, p) => new PlayerCard(id, p))
		this.random = random
	}
}
