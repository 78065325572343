import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useFormik } from 'formik'

import { FormikForm, FormikSwitch } from 'components'
import { useGame } from '../game-context'

const Component = (props: { className?: string }) => {
	const { yourPlayer, round } = useGame()
	const { settings } = yourPlayer

	const [show, setShow] = useState(false)

	const formik = useFormik({
		initialValues: {
			enableAutoPass: settings.enableAutoPass,
			enableBreakupMultipleConfirmation: settings.enableBreakupMultipleConfirmation,
			enableSounds: settings.enableSounds,
		},
		onSubmit: (values) => {
			round.manager.updateSettings(values).then((result) => {
				if (!result.success) {
					alert(result.errors?.join('\n'))
				} else {
					setShow(false)
				}
			})
		},
	})

	const handleClose = () => {
		formik.resetForm()
		setShow(false)
	}
	const handleShow = () => setShow(true)

	return (
		<div className={props.className}>
			<Button size='sm' variant='link' onClick={handleShow}>
				<i className='fas fa-cog'></i> Settings
			</Button>
			<Modal show={show} onHide={handleClose}>
				<FormikForm formik={formik}>
					<Modal.Header closeButton>
						<Modal.Title>Settings</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FormikSwitch
							label='Enable Auto Pass'
							name='enableAutoPass'
							helperText={`When enabled, if you have no playable cards on your turn, you will automatically pass for the round.`}
						/>
						<FormikSwitch
							label='Enable Breakup Multiple Confirmation'
							name='enableBreakupMultipleConfirmation'
							helperText={`When enabled, if you try to play any cards that will break up a larger multiple, it will prompt you to confirm.`}
						/>
						<FormikSwitch
							label='Enable Sounds'
							name='enableSounds'
							helperText={`When enabled, sounds will play for the game, for example, a sound alerting you that it's your turn.`}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Close
						</Button>
						<Button variant='primary' type='submit'>
							Save Changes
						</Button>
					</Modal.Footer>
				</FormikForm>
			</Modal>
		</div>
	)
}

export const Settings = styled(Component)`
	.btn-link {
		text-decoration: none;
	}
`
