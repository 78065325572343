import { useState, useEffect } from 'react'
import { firebaseService, FirebaseService } from './firebase.service'

export interface UserInfo {
	id: string
	name: string
	authenticated: boolean
}

export class AuthService {
	constructor(private _firebaseService: FirebaseService) {}

	useFirebaseUser() {
		const [user, setUser] = useState<UserInfo>()

		useEffect(() => {
			this._firebaseService.auth.onAuthStateChanged((user) => {
				if (user) {
					setUser({
						id: user.uid,
						name: user.displayName || 'Unknown User',
						authenticated: true,
					})
				} else {
					setUser({
						authenticated: false,
						name: '',
						id: '',
					})
				}
			})
		}, [])

		return user
	}

	signOut() {
		return this._firebaseService.auth.signOut()
	}
}

export const authService = new AuthService(firebaseService)
