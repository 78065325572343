import React, { ReactNode } from 'react'
import { Sound as SoundType, SoundOptions, Sounds } from 'utils'

export interface SoundProps extends Partial<SoundOptions> {
	children?: ReactNode
	audio: SoundType
	condition: boolean
}

export const Sound = ({ children, audio, condition, ...options }: SoundProps) => {
	Sounds.use(condition, audio, options)
	return <>{children}</>
}
