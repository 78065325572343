import React from 'react'
import styled from 'styled-components'
import { useGame } from '../game-context'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

const Component = (props: { className?: string }) => {
	const { yourPlayer: player, round, currentTurnPlayer } = useGame()

	if (round.status !== 'Playing' || player.passed) {
		return <></>
	}

	const onPass = () => {
		round.manager.pass().then((result) => {
			if (!result.success) {
				alert(result.errors?.join('\n'))
			}
		})
	}

	return (
		<div className={props.className}>
			<div className='play-size-container bg-secondary text-light'>
				<h3
					className={classNames('mb-3', {
						'animate__animated animate__swing animate__infinite animate__delay-5s':
							player.isYourTurn,
					})}
				>
					{player.isYourTurn ? (
						<span className='text-warning'>It's your turn!</span>
					) : (
						<>
							Turn: <span className='text-warning'>{currentTurnPlayer.name}</span>
						</>
					)}
				</h3>
				<div>
					<Button onClick={onPass} variant='light'>
						{player.isYourTurn ? 'Pass' : 'Pass this Round'}
					</Button>
				</div>
			</div>
		</div>
	)
}

export const PlayerPass = styled(Component)`
	margin-bottom: 20px;
	display: flex;
	justify-content: center;

	.play-size-container {
		padding: 20px;
		border-radius: 20px;
	}

	@media (max-height: 700px) {
		.play-size-container {
			padding: 10px 20px;
			h3 {
				font-size: 24px;
			}
		}
	}
`
