export const palette = {
	colors: {
		primary: '#027043',
		secondary: '#6c757d',
		success: '#28a745',
		warning: '#ffc107',
		danger: '#dc3545',
	},
	card: {
		clampWidth: {
			minPixels: 20,
			maxPixels: 128,
			viewWidth: 10,
		},
		borderRadius: '10% / 6%',
		overlapPercent: 0.6,
	},
}
