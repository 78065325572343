import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useUser } from '../contexts'
import { authService } from '../services'

const UserNav = () => {
	const user = useUser()

	if (!user) {
		return <></>
	}

	return (
		<Nav>
			<NavDropdown alignRight title={user.name} id='account-dropdown'>
				<NavDropdown.Item as={Link} to='/account'>
					Account
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item onClick={() => authService.signOut()}>Logout</NavDropdown.Item>
			</NavDropdown>
		</Nav>
	)
}

export const Navigation = () => {
	const user = useUser()

	return (
		<Navbar bg='primary' variant='dark' expand='lg'>
			<Navbar.Brand as={Link} to='/'>
				Delfonzo Games
			</Navbar.Brand>
			<Navbar.Toggle aria-controls='basic-navbar-nav' />
			<Navbar.Collapse id='basic-navbar-nav'>
				<Nav className='mr-auto'>
					<Nav.Link as={Link} to='/'>
						Lobbies
					</Nav.Link>
				</Nav>
				{user && <UserNav />}
				{!user && (
					<Nav>
						<Nav.Link as={Link} to='/login'>
							Login
						</Nav.Link>
					</Nav>
				)}
			</Navbar.Collapse>
		</Navbar>
	)
}
