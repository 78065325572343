import { DatabaseService, LobbyService, lobbyService, DbResult } from 'services'
import { firebaseService } from './firebase.service'
import { useState, useEffect } from 'react'
import { ScumJson } from 'models/games/scum'

export interface UseGameModel {
	isInGame: boolean
}

export class GameService<T> {
	private constructor(private games: DatabaseService<T>, private lobbyService: LobbyService) {}

	static construct<T>() {
		return new GameService(new DatabaseService<T>(firebaseService, 'games'), lobbyService)
	}

	async create(lobbyId: string, game: T) {
		await this.games.set(lobbyId, game)
		await this.lobbyService.startGameForLobby(lobbyId)
	}

	useGame(gameId: string, userId: string): DbResult<UseGameModel> {
		const [result, setResult] = useState<DbResult<UseGameModel>>({ loading: true })

		useEffect(() => {
			let ref: firebase.database.Reference | undefined = undefined

			if (gameId && userId) {
				this.isPartOfGame(gameId, userId).then((isPartOfGame) => {
					if (!isPartOfGame) {
						setResult({ loading: false, data: { isInGame: false } })
					} else {
						ref = this.games.ref.child(`${gameId}/players/${userId}/connected`)
						ref.onDisconnect().remove()
						ref.set(true).then(() => {
							setResult({ loading: false, data: { isInGame: true } })
						})
					}
				})
			}

			return () => {
				if (gameId && userId && ref) {
					ref.remove()
					ref.onDisconnect().cancel()
				}
			}
		}, [gameId, userId])

		return result
	}

	useGet(id: string) {
		return this.games.useGet(id)
	}

	private async isPartOfGame(gameId: string, userId: string) {
		const result = await this.games.ref.child(`${gameId}/players/${userId}`).once('value')
		return result.exists()
	}

	transaction(id: string, transaction: (game: T) => T): Promise<void> {
		return this.games.transaction(id, transaction)
	}
}

export const scumGameService = GameService.construct<ScumJson>()
