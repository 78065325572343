import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { firebaseUiConfig, firebaseService } from '../services/firebase.service'
import { Card, Row, Col } from 'react-bootstrap'
import { useAppUser } from '../contexts'
import { userService } from '../services'

export const LoginPage = () => {
	const user = useAppUser()
	const history = useHistory()

	useEffect(() => {
		if (user.authenticated) {
			userService.updateUserFromInfo(user).then(({ unknownUser }) => {
				if (unknownUser) {
					history.replace('/account')
				} else {
					history.replace('/')
				}
			})
		}
	}, [user, history])

	return (
		<Row className='justify-content-center'>
			<Col md={6}>
				<Card>
					<Card.Body>
						{user.authenticated && (
							<>
								<Card.Title>Logging in</Card.Title>
								<Card.Text className='mb-5'>
									Logging you into your account...
								</Card.Text>
							</>
						)}
						{!user.authenticated && (
							<>
								<Card.Title>Login</Card.Title>
								<Card.Text className='mb-5'>
									Please log in using your Google Account.
								</Card.Text>
								<StyledFirebaseAuth
									uiConfig={firebaseUiConfig}
									firebaseAuth={firebaseService.auth}
								/>
							</>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}
