import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { Alert } from 'react-bootstrap'

import { scumGameService } from 'services'
import { useAppUser } from 'contexts'
import { GameUI } from 'games/scum'

export const GamePage = () => {
	const { id } = useParams()
	const user = useAppUser()
	const result = scumGameService.useGame(id, user.id)

	if (result.loading) {
		return <>Loading...</>
	}

	if (!result.data) {
		return <Alert variant='danger'>There was an error trying to load the game.</Alert>
	}

	if (!result.data.isInGame) {
		return <Redirect to='/' />
	}

	return <GameUI />
}
