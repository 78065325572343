export interface CommandResultLike {
	success: boolean
	errors: string[] | undefined
}

export class CommandResult<T> implements CommandResultLike {
	static success<T>(payload?: T) {
		return new CommandResult(true, payload, undefined)
	}

	static failure<T = undefined>(...errors: string[]) {
		return new CommandResult<T>(false, undefined, errors)
	}

	private constructor(
		public success: boolean,
		public payload: T | undefined,
		public errors: string[] | undefined,
	) {}
}
