import React from 'react'

import { useFormikForm } from './formik-form.context'
import { Form } from 'react-bootstrap'

export interface FormikControlProps {
	/** The form field name (should be the same name as registered with 'useFormik()') */
	name: string
	/** The text label for the field */
	label: string
	/** The placeholder text for the field */
	placeholder?: string
	/** Field type */
	type?: string
	readOnly?: boolean
	plaintext?: boolean
}

/**
 * A Wrapper TextField/FormGroup component that disables itself if it's parent FormikForm is disabled and sets up errors, events, etc
 * @param props
 */
export const FormikControl = ({ name, label, ...control }: FormikControlProps) => {
	const { formik, disabled } = useFormikForm()
	return (
		<Form.Group controlId={name}>
			<Form.Label>{label}</Form.Label>
			<Form.Control
				{...formik.getFieldProps(name)}
				disabled={disabled}
				{...control}
				isInvalid={formik.touched[name] && !!formik.errors[name]}
			/>
			<Form.Control.Feedback type='invalid'>
				{formik.touched[name] && formik.errors[name]}
			</Form.Control.Feedback>
		</Form.Group>
	)
}
