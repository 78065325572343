import { useGame } from '../game-context'
import { useEffect } from 'react'

/** During your turn while the round is playing, check to see if you've passed. If you haven't but don't have any cards you can play, pass. */
export function usePassCheck() {
	const { round, yourPlayer: you } = useGame()

	useEffect(() => {
		if (you.isYourTurn && round.status === 'Playing') {
			if (!you.passed) {
				const playable = you.cardGroups.find((p) => p.playable)
				// if you have playable cards DON'T auto pass
				// if you don't have playable cards, see if you have AutoPass disabled, only stop if it's not an Ace.
				if (
					you.cardGroups.length !== 0 &&
					(playable || (!you.settings.enableAutoPass && round.lastValue !== 14)) /* Ace */
				) {
					return
				}
			}
			round.manager.pass().then((result) => {
				if (!result.success) {
					alert(result.errors?.join('\n'))
				}
			})
		}
	}, [
		you.isYourTurn,
		you.passed,
		you.cardGroups,
		round.status,
		round.manager,
		you.settings.enableAutoPass,
		round.lastValue,
	])
}
