import styled from 'styled-components'
import { palette } from 'assets/palette'

export const Board = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${palette.colors.primary};
`
