import React, { ReactNode } from 'react'
import classNames from 'classnames'

export const FlexInfo = (props: { className?: string; label: string; children: ReactNode }) => {
	return (
		<div className={classNames('d-flex', props.className)}>
			<strong className='mr-auto font-weight-normal'>{props.label}</strong>
			<span className='font-weight-light'>{props.children}</span>
		</div>
	)
}
