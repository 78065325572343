import { GameService } from 'services'
import { ScumJson, ScumPlayerJson, ScumPlayerSettingsJson } from 'models/games/scum'
import { CommandResultLike, CommandResult } from 'models'
import { CardGroup } from './view-models/card-group'
import { ArrayUtils } from 'utils'
import { GameManager } from './game-manager'

interface EndRoundResult {
	shouldStartNewGame: boolean
}

export class RoundManager {
	constructor(
		private userId: string,
		private gameId: string,
		private gameService: GameService<ScumJson>,
	) {}

	async updateSettings(settings: ScumPlayerSettingsJson): Promise<CommandResultLike> {
		let result: CommandResultLike = CommandResult.success()

		await this.gameService.transaction(this.gameId, (root) => {
			root.players[this.userId].settings = { ...settings }

			return root
		})

		return result
	}

	async setPlaySize(playSize: number): Promise<CommandResultLike> {
		let result: CommandResultLike = CommandResult.success()

		if (playSize <= 0) {
			return CommandResult.failure('Play size must be 1 or more.')
		}

		await this.gameService.transaction(this.gameId, (root) => {
			if (root.currentTurnUserId !== this.userId) {
				result = CommandResult.failure('It is not your turn.')
				return root
			}

			if (root.status !== 'Playing Rounds') {
				result = CommandResult.failure(
					'You cannot change the play size outside of round play.',
				)
				return root
			}

			if (root.round.status !== 'Start') {
				result = CommandResult.failure(
					'You cannot change the play size after the round has started.',
				)
				return root
			}

			// we good, set the play size
			root.round.playSize = playSize

			return root
		})

		return result
	}

	async claimNextOrdinal(): Promise<CommandResultLike> {
		let result: CommandResultLike = CommandResult.success()

		await this.gameService.transaction(this.gameId, (root) => {
			if (root.status !== 'Playing Rounds') {
				result = CommandResult.failure(
					"You can't claim Royalty when the game hasn't started.",
				)
				return root
			}

			const you = root.players[this.userId]

			if (you.nextOrdinal !== undefined) {
				result = CommandResult.failure("You've already claimed Royalty.")
				return root
			}

			const cards = ArrayUtils.fromFirebaseArray(you.cards)

			if (cards.length > 0) {
				result = CommandResult.failure("You still have cards, you can't claim Royalty.")
				return root
			}

			const players = ArrayUtils.fromFirebaseArray(root.players)
			you.nextOrdinal = players.filter((p) => p.nextOrdinal !== undefined).length

			// if there's only 1 player that has no claimed, start new game
			if (players.count((p) => p.nextOrdinal === undefined) <= 1) {
				return this.transaction_startNewGame(root)
			}

			return root
		})

		return result
	}

	async playCardGroup(cardGroup: CardGroup): Promise<CommandResultLike> {
		let result: CommandResultLike = CommandResult.success()

		await this.gameService.transaction(this.gameId, (root) => {
			if (root.currentTurnUserId !== this.userId) {
				result = CommandResult.failure('It is not your turn.')
				return root
			}

			const plays = ArrayUtils.fromFirebaseArray(root.round.plays)
			const lastPlay = plays.last()
			if (lastPlay && lastPlay.value >= cardGroup.value) {
				result = CommandResult.failure('You must play a higher card.')
				return root
			}

			plays.push({
				userId: this.userId,
				value: cardGroup.value,
				face: cardGroup.face,
				cards: ArrayUtils.toFirebaseArray(cardGroup.cards, (p) => p.id),
				random: Math.random(),
			})

			root.round.plays = ArrayUtils.toFirebaseArray(plays)

			for (const card of cardGroup.cards) {
				delete root.players[this.userId].cards[card.id]
			}

			if (root.round.status === 'Start') {
				root.round.status = 'Playing'
			}

			const trySetResult = this.transaction_trySetNextPlayer(root)
			if (trySetResult.shouldStartNewGame) {
				return this.transaction_startNewGame(root)
			}

			return root
		})

		return result
	}

	async pass(): Promise<CommandResultLike> {
		let result: CommandResultLike = CommandResult.success()

		await this.gameService.transaction(this.gameId, (root) => {
			if (root.round.status === 'Ended') {
				result = CommandResult.failure('You cannot pass when the round has ended.')
				return root
			}

			root.players[this.userId].passed = true

			if (root.currentTurnUserId === this.userId) {
				const result = this.transaction_trySetNextPlayer(root)

				if (result.shouldStartNewGame) {
					return this.transaction_startNewGame(root)
				}
			}

			return root
		})

		return result
	}

	private transaction_startNewGame(root: ScumJson): ScumJson {
		// order players by new order
		const players = ArrayUtils.fromFirebaseArray(root.players).orderBy((p) => p.nextOrdinal)

		// verify new ordinals
		const noNextOrdinal = players.filter((p) => p.nextOrdinal === undefined)
		// if someone is missing an ordinal, assume those with no cards are done
		if (noNextOrdinal.length === 2) {
			const playerWithNoCards = noNextOrdinal.find(
				(p) => ArrayUtils.fromFirebaseArray(p.cards).length === 0,
			)
			if (playerWithNoCards) {
				playerWithNoCards.nextOrdinal = players.length - 2
			}
		}
		// if there is more than one ordinal, throw error
		if (noNextOrdinal.length > 1) {
			throw new Error(
				`There were ${noNextOrdinal.length} players found with no next ordinal.`,
			)
		}

		// setup players
		const scumPlayers: Array<ScumPlayerJson> = players
			.map((p) => {
				return {
					cards: {},
					name: p.name,
					ordinal: p.nextOrdinal !== undefined ? p.nextOrdinal : players.length - 1,
					passed: false,
					userId: p.userId,
					connected: p.connected,
					settings: p.settings,
				} as ScumPlayerJson
			})
			.orderBy((p) => p.ordinal)

		return GameManager.createNewGame(
			scumPlayers,
			root.gameCount + 1,
			scumPlayers[0].userId,
			root.round,
		)
	}

	async endRound(): Promise<CommandResultLike> {
		let result: CommandResultLike = CommandResult.success()

		await this.gameService.transaction(this.gameId, (root) => {
			if (root.round.status !== 'Playing') {
				return root
			}

			const endRoundResult = this.transaction_endRound(root)
			if (endRoundResult.shouldStartNewGame) {
				return this.transaction_startNewGame(root)
			}

			return root
		})

		return result
	}

	private transaction_trySetNextPlayer(root: ScumJson): EndRoundResult {
		const nextUserId = RoundManager.getNextPlayersTurn(root)
		if (nextUserId) {
			root.currentTurnUserId = nextUserId
		} else {
			return this.transaction_endRound(root)
		}
		return { shouldStartNewGame: false }
	}

	private transaction_endRound(root: ScumJson): EndRoundResult {
		// end current round status
		root.round.status = 'Ended'

		// set new current user turn
		const nextStartingPlayerId = this.transaction_getNextStartingPlayer(root)
		if (!nextStartingPlayerId || RoundManager.howManyPlayersHaveCards(root) <= 1) {
			// we couldn't find another player to start the next round or one or less players have cards, start a new game
			return { shouldStartNewGame: true }
		}
		root.currentTurnUserId = nextStartingPlayerId

		// create new round
		root.lastRound = { ...root.round }
		root.round = {
			plays: {},
			status: 'Start',
		}

		// reset player passings
		const players = ArrayUtils.fromFirebaseArray(root.players)
		for (const player of players) {
			player.passed = ArrayUtils.fromFirebaseArray(player.cards).length === 0
		}

		return { shouldStartNewGame: false }
	}

	private transaction_getNextStartingPlayer(root: ScumJson): string | undefined {
		let startingPlayerUserId = ArrayUtils.fromFirebaseArray(root.round.plays).last()?.userId
		if (!startingPlayerUserId) {
			throw new Error(
				"Couldn't get the next starting player because no one played any cards this round...",
			)
		}

		// check if they have cards
		if (ArrayUtils.fromFirebaseArray(root.players[startingPlayerUserId].cards).length === 0) {
			startingPlayerUserId = RoundManager.getNextPlayerWithCards(root, startingPlayerUserId)
			if (!startingPlayerUserId) {
				return undefined
			}
		}

		return startingPlayerUserId
	}

	static getNextPlayersTurn(root: ScumJson): string | undefined {
		const players = ArrayUtils.fromFirebaseArray(root.players).orderBy((p) => p.ordinal)
		let index = players.findIndex((p) => p.userId === root.currentTurnUserId)
		index++
		if (index >= players.length) {
			index = 0
		}

		let checkCount = 0
		while (players[index].passed) {
			if (checkCount > players.length) {
				return undefined
			}

			index++
			if (index >= players.length) {
				index = 0
			}
			checkCount++
		}
		return players[index].userId
	}

	static getNextPlayerWithCards(root: ScumJson, currentUserId: string): string | undefined {
		const players = ArrayUtils.fromFirebaseArray(root.players).orderBy((p) => p.ordinal)
		let index = players.findIndex((p) => p.userId === currentUserId)
		index++
		if (index >= players.length) {
			index = 0
		}

		let checkCount = 0
		while (ArrayUtils.fromFirebaseArray(players[index].cards).length === 0) {
			if (checkCount > players.length) {
				return undefined
			}

			index++
			if (index >= players.length) {
				index = 0
			}
			checkCount++
		}
		return players[index].userId
	}

	static howManyPlayersHaveCards(root: ScumJson): number {
		const players = ArrayUtils.fromFirebaseArray(root.players)
		return players.count((p) => ArrayUtils.fromFirebaseArray(p.cards).length > 0)
	}
}
