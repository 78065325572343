import { useGame } from '../game-context'
import { useEffect } from 'react'

/** If it's your turn to start a round, but you only have one kind of play size, auto select it. */
export function usePlaySizeCheck() {
	const { round, yourPlayer: you, currentTurnPlayer } = useGame()

	const isYourTurn = you.userId === currentTurnPlayer.userId
	const isRoundToStart = round.status === 'Start'
	const isPlaySizeNotSet = !round.playSize
	const playSizes = you.getPlaySizes()

	useEffect(() => {
		if (isYourTurn && isRoundToStart && isPlaySizeNotSet && playSizes.length === 1) {
			round.manager.setPlaySize(playSizes[0]).then((result) => {
				if (!result.success) {
					alert(result.errors?.join('\n'))
				}
			})
		}
	}, [isYourTurn, isRoundToStart, isPlaySizeNotSet, playSizes, round.manager])
}
