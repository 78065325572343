import { PlayerCard } from './player-card'
import {
	ScumPlayerJson,
	ScumPlayerCardsTransferredJson,
	ScumPlayerSettingsJson,
} from 'models/games/scum'
import { FirebaseArray, GamePlayerJson } from 'models'
import { ArrayUtils } from 'utils'
import { GamePlayer } from 'games/game-player'
import { CardGroup } from './card-group'

export class ScumPlayer extends GamePlayer {
	ordinal: number
	nextOrdinal?: number
	passed: boolean
	cardGroups: Array<CardGroup>
	cards: Array<PlayerCard>
	cardsGiven?: ScumPlayerCardsTransferred
	cardsReceived?: ScumPlayerCardsTransferred
	settings: ScumPlayerSettingsJson

	get hasExchangedCards() {
		return !!this.cardsGiven && !!this.cardsReceived
	}

	get cardsExchangedId() {
		if (!this.hasExchangedCards) {
			return undefined
		}
		return `${this.cardsGiven?.cards
			.map((p) => p.id)
			.join('--')}-xXx-${this.cardsReceived?.cards.map((p) => p.id).join('--')}`
	}

	constructor(
		public id: string,
		players: FirebaseArray<GamePlayerJson>,
		model: ScumPlayerJson,
		public isYourTurn: boolean,
		lastPlayedValue: number | undefined,
		playSize?: number,
	) {
		super(model)
		this.settings = model.settings
		this.ordinal = model.ordinal
		this.passed = model.passed
		this.nextOrdinal = model.nextOrdinal
		this.cards = ArrayUtils.mapFromFirebaseArray(
			model.cards,
			(id, p) => new PlayerCard(id, p),
		).orderBy((p) => p.value)
		this.cardGroups = this._buildCardGroups(playSize, lastPlayedValue)
		if (model.cardsGiven) {
			this.cardsGiven = new ScumPlayerCardsTransferred(players, model.cardsGiven)
		}
		if (model.cardsReceived) {
			this.cardsReceived = new ScumPlayerCardsTransferred(players, model.cardsReceived)
		}
	}

	private _buildCardGroups(
		playSize: number | undefined,
		lastPlayedValue: number | undefined,
	): Array<CardGroup> {
		if (!this.isYourTurn || !this.cards.length || !playSize) {
			return this.cards.map((p) => new CardGroup([p], p.face, p.value))
		}
		if (playSize === 1) {
			return this.cards.map(
				(p) =>
					new CardGroup(
						[p],
						p.face,
						p.value,
						!lastPlayedValue || lastPlayedValue < p.value,
					),
			)
		}

		const groups: Array<CardGroup> = [
			new CardGroup([this.cards[0]], this.cards[0].face, this.cards[0].value),
		]

		for (let i = 1; i < this.cards.length; i++) {
			const card = this.cards[i]
			const lastGroup = groups.last() as CardGroup

			if (lastGroup.cards.length === playSize) {
				lastGroup.playable = !lastPlayedValue || lastPlayedValue < lastGroup.value
				groups.push(new CardGroup([card], card.face, card.value))
			} else if (lastGroup.face !== card.face) {
				lastGroup.playable = false
				groups.push(new CardGroup([card], card.face, card.value))
			} else {
				lastGroup.cards.push(card)
			}
		}
		const lastGroup = groups.last() as CardGroup
		lastGroup.playable =
			lastGroup.cards.length === playSize &&
			(!lastPlayedValue || lastPlayedValue < lastGroup.value)

		return groups
	}

	/** Get an array of play size options for the players current hand */
	getPlaySizes(): Array<number> {
		if (!this.cards.length) {
			return []
		}

		const counts: FirebaseArray<number> = {}
		this.cards.forEach((p) => (counts[p.face] = (counts[p.face] || 0) + 1))
		const max = Math.max(...ArrayUtils.fromFirebaseArray(counts))

		const playSizes: Array<number> = []
		for (let i = max; i >= 1; i--) {
			playSizes.push(i)
		}
		return playSizes.orderBy((p) => p)
	}
}

class ScumPlayerCardsTransferred {
	cards: Array<PlayerCard>
	player: GamePlayerJson

	constructor(players: FirebaseArray<GamePlayerJson>, model: ScumPlayerCardsTransferredJson) {
		this.cards = ArrayUtils.mapFromFirebaseArray(model.cards, (id, p) => new PlayerCard(id, p))
		this.player = players[model.userId]
	}
}
