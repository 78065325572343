import React from 'react'
import styled from 'styled-components'
import { useGame } from '../game-context'
import { ArrayUtils } from 'utils'
import { Card } from './Card'
import classNames from 'classnames'

const Component = (props: { className?: string }) => {
	const { round, players, lastRound } = useGame()
	const { plays: currentPlays } = round

	let plays = currentPlays
	let isPreviousRound = false

	if (!plays || !plays.length) {
		if (lastRound) {
			isPreviousRound = true
			plays = lastRound.plays
			if (!plays.length) {
				return <></>
			}
		} else {
			return <></>
		}
	}

	const playerLookup = ArrayUtils.toFirebaseArray(players, (p) => p.userId)

	return (
		<div className={classNames(props.className, 'bg-secondary')}>
			{isPreviousRound && <div className='text-white text-center mb-3'>Previous Round</div>}
			<div className='plays'>
				{plays.map((play, index) => (
					<div className='play animate__animated animate__fadeInRight' key={play.id}>
						<div
							className={classNames('player', {
								'text-warning': isPreviousRound && index === plays.length - 1,
							})}
						>
							{playerLookup[play.userId].name}
						</div>
						<div className='played-cards'>
							{play.cards.map((card) => (
								<Card key={card.id} card={card} />
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export const RoundPlayList = styled(Component)`
	position: absolute;
	top: 0;
	right: 0;
	padding: 20px;
	border-bottom-left-radius: 20px;

	.play {
		margin-top: 10px;
		display: none;
		align-items: center;

		&:nth-last-child(-n + 3) {
			display: flex;
		}

		&:first-child {
			margin-top: 0;
		}

		.played-cards {
			display: flex;
			justify-content: center;

			${Card} {
				margin: 0 5px;
				width: 30px;
			}
		}

		.player {
			flex: 1;
			color: white;
			text-align: right;
			padding-right: 10px;
		}
	}
`
