import { GamePlayerJson } from 'models'

export class GamePlayer implements GamePlayerJson {
	userId: string
	name: string
	connected: boolean

	constructor({ userId, name, connected }: GamePlayerJson) {
		this.userId = userId
		this.name = name
		this.connected = !!connected
	}
}
