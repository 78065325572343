import React, { ReactNode, useState } from 'react'
import { useGame } from '../game-context'
import { Sound as AudioSound } from 'utils'
import { Sound } from 'components'

export interface RandomTadaProps {
	ordinal: number
	children?: ReactNode
}

const sounds: Array<AudioSound> = [
	'misc/tada-fanfare-a-major.flac',
	'misc/tada-fanfare-f-major.flac',
	'misc/tada-fanfare-g-major.flac',
	'misc/tada.wav',
]

export const RandomTada = ({ children, ordinal }: RandomTadaProps) => {
	const { players, yourPlayer } = useGame()
	const [audio] = useState(sounds[Math.floor(Math.random() * sounds.length)])

	return (
		<Sound
			condition={yourPlayer.settings.enableSounds && ordinal < players.length - 1}
			audio={audio}
		>
			{children}
		</Sound>
	)
}
