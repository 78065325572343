import React from 'react'
import { Row, Col, Card, Alert } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { userService } from '../services'
import { useAppUser } from '../contexts'
import { UserJson } from '../models'
import { FormikForm, FormikControl, FormikButton } from '../components'
import { ErrorMessage } from '../utils'

const AccountForm = (props: { userId: string; user: UserJson }) => {
	const [result, update] = userService.useUpdate(props.userId)

	const formik = useFormik({
		initialValues: {
			name: props.user.name,
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.required(ErrorMessage.required('Name'))
				.max(50, ErrorMessage.max('Name')),
		}),
		onSubmit: (values) => {
			update({
				name: values.name,
			})
		},
	})

	return (
		<FormikForm formik={formik} disabled={result.loading}>
			{result.success && <Alert variant='success'>Account details saved!</Alert>}
			{result.success === false && <Alert variant='danger'>Unexpected error!</Alert>}
			<FormikControl label='Name' placeholder='Enter name' name='name' />
			<FormikButton variant='primary' type='submit'>
				Save
			</FormikButton>
		</FormikForm>
	)
}

export const AccountPage = () => {
	const info = useAppUser()
	const { loading, data } = userService.useGet(info.id)

	if (loading || !data) return <></>

	return (
		<Row className='justify-content-center'>
			<Col md={6}>
				<Card>
					<Card.Body>
						<Card.Title>Account Details</Card.Title>
						<AccountForm userId={info.id} user={data} />
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}
