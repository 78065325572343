import React from 'react'
import { RouteProps, Route, Redirect } from 'react-router-dom'
import { useUser } from '../contexts'

export const PrivateRoute = ({ children, component, ...props }: RouteProps) => {
	const user = useUser()
	const Component = component as any

	return (
		<Route
			{...props}
			render={({ location }) =>
				user ? (
					children || <Component />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	)
}
