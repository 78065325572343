import { useState, useEffect } from 'react'

import { Lobby, CommandResult, LobbyJson } from '../models'
import { firebaseService } from './firebase.service'
import { userService } from './user.service'
import { DatabaseService } from './database.service'
import { DbListResult, DbResult, DbListItem } from './database-models'

export interface LobbyJoinResult {
	loading: boolean
	joined?: boolean
}

export class LobbyService {
	constructor(private lobbies: DatabaseService<LobbyJson>) {}

	useGetLobbies(): DbListResult<Lobby> {
		const { loading, items } = this.lobbies.useGetList()
		return {
			loading,
			items: items.map((p) => {
				return { id: p.id, data: new Lobby(p.data) }
			}),
		}
	}

	useGet(id: string): DbResult<Lobby> {
		const { loading, data } = this.lobbies.useGet(id)

		return {
			loading,
			data: data && new Lobby(data),
		}
	}

	async get(id: string): Promise<Lobby | undefined> {
		const result = await this.lobbies.get(id)
		if (!result) {
			return undefined
		}
		return new Lobby(result)
	}

	startGameForLobby(lobbyId: string) {
		return this.lobbies.transaction(lobbyId, (data) => {
			data.status = 'Started'
			return data
		})
	}

	async create(
		lobby: Pick<LobbyJson, 'name' | 'maxPlayers' | 'game'>,
		userId: string,
	): Promise<CommandResult<DbListItem<Lobby> | undefined>> {
		const user = await userService.get(userId)
		if (!user) {
			return CommandResult.failure<DbListItem<Lobby>>(
				`Could not find user with id: ${userId}.`,
			)
		}

		const lobbyModel: LobbyJson = {
			...lobby,
			date: Date.now(),
			host: {
				name: user.name,
				userId,
			},
			players: {},
			status: 'Waiting for Players',
		}

		const result = await this.lobbies.add(lobbyModel)

		return CommandResult.success<DbListItem<Lobby>>({
			id: result.id,
			data: new Lobby(lobbyModel),
		})
	}

	update(id: string, data: Partial<LobbyJson>) {
		return this.lobbies.update(id, data)
	}

	useJoin = (userId: string, lobbyId: string) => {
		const [result, setResult] = useState<LobbyJoinResult>({ loading: true })

		useEffect(() => {
			let ref: firebase.database.Reference | undefined = undefined

			if (userId && lobbyId) {
				setResult({ loading: true })
				this.join(userId, lobbyId)
					.then((info) => {
						setResult({ loading: false, joined: info.joined })
						if (info.joined && !info.started) {
							ref = this.lobbies.ref.child(`${lobbyId}/players/${info.index}`)
							ref.onDisconnect().remove()
						}
					})
					.catch(() => {
						console.error('Error trying to joined the lobby')
					})
			}

			return () => {
				if (userId && lobbyId) {
					this.leave(userId, lobbyId)
					if (ref) {
						ref.onDisconnect().cancel()
					}
				}
			}
		}, [userId, lobbyId])

		return result
	}

	private async join(userId: string, lobbyId: string) {
		let info = { joined: false, index: -1, started: false }

		const user = await userService.get(userId)
		if (user) {
			await this.lobbies.transaction(lobbyId, (lobbyJson) => {
				const lobby = new Lobby(lobbyJson)
				let yourself = lobby.players.find((p) => p.userId === userId)

				if (yourself) {
					info.joined = true
					info.index = lobby.players.indexOf(yourself)
					return lobby.toJson()
				}

				if (
					!yourself &&
					lobby.players.length < lobby.maxPlayers &&
					lobby.status === 'Waiting for Players'
				) {
					yourself = {
						userId,
						name: user.name,
					}
					lobby.addPlayer(yourself)
					info.joined = true
					info.index = lobby.players.indexOf(yourself)
				}

				info.started = lobby.status === 'Started'

				return lobby.toJson()
			})
		}
		return info
	}

	private leave(userId: string, lobbyId: string) {
		return this.lobbies.transaction(lobbyId, (lobbyJson) => {
			const lobby = new Lobby(lobbyJson)
			if (lobby.status === 'Waiting for Players') {
				lobby.removePlayer(userId)
			}
			return lobby.toJson()
		})
	}
}

export const lobbyService = new LobbyService(
	new DatabaseService<LobbyJson>(firebaseService, 'lobbies'),
)
