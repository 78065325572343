import { FirebaseService } from './firebase.service'
import { useState, useEffect } from 'react'
import {
	DbListItem,
	DbCommandResult,
	DbResult,
	DbListResult,
	FirebaseDbService,
} from './database-models'

export class DatabaseService<T> implements FirebaseDbService<T> {
	constructor(private _firebaseService: FirebaseService, private _collectionName: string) {}

	static firebaseKeyValuesToArray<T>(obj: { [key: string]: T }): Array<DbListItem<T>> {
		return Object.entries<T>(obj).map(([id, data]) => {
			return {
				id,
				data,
			}
		})
	}

	private get db() {
		return this._firebaseService.database
	}

	get ref() {
		return this.db.ref(this._collectionName)
	}

	transaction(id: string, transaction: (data: T) => T): Promise<void> {
		let canResolve = false
		return new Promise((resolve) => {
			this.ref
				.child(id)
				.transaction((data) => {
					if (data) {
						canResolve = true
						return transaction(data)
					}
					return data
				})
				.then(() => {
					if (canResolve) {
						resolve()
					}
				})
		})
	}

	useTransaction(id: string): [DbCommandResult, (transaction: (data: T) => T) => Promise<void>] {
		const [result, setResult] = useState<DbCommandResult>({ loading: false })

		const transaction = (transaction: (data: T) => T) => {
			setResult({ loading: true, success: undefined })
			return this.transaction(id, transaction)
				.then(() => setResult({ loading: false, success: true }))
				.catch(() => setResult({ loading: false, success: false }))
		}

		return [result, transaction]
	}

	update(id: string, data: Partial<T>): Promise<void> {
		return this.ref.child(id).update(data)
	}

	useUpdate(id: string): [DbCommandResult, (data: Partial<T>) => Promise<void>] {
		const [result, setResult] = useState<DbCommandResult>({ loading: false })

		const update = (data: Partial<T>) => {
			setResult({ loading: true, success: undefined })
			return this.update(id, data)
				.then(() => setResult({ loading: false, success: true }))
				.catch(() => setResult({ loading: false, success: false }))
		}

		return [result, update]
	}

	async get(id: string): Promise<T | null | undefined> {
		const result = await this.ref.child(id).once('value')
		return result.val() as T | null | undefined
	}

	useGet = (id: string, once?: boolean): DbResult<T> => {
		const [data, setData] = useState<DbResult<T>>({ loading: true })

		useEffect(() => {
			if (id) {
				const ref = this.ref.child(id)

				const onValueChange = (snapshot: firebase.database.DataSnapshot) => {
					setData({
						loading: false,
						data: snapshot.val(),
					})
				}

				if (once) {
					ref.once('value', onValueChange)
					return () => {}
				} else {
					ref.on('value', onValueChange)
					return () => {
						ref.off('value', onValueChange)
					}
				}
			}

			setData({ loading: false, data: undefined })
			return () => {}
		}, [id, once])

		return data
	}

	async getList(): Promise<Array<DbListItem<T>>> {
		const result = await this.ref.once('value')
		return DatabaseService.firebaseKeyValuesToArray<T>(result.val())
	}

	useGetList(): DbListResult<T> {
		const [data, setData] = useState<DbListResult<T>>({ loading: true, items: [] })

		useEffect(() => {
			const dictionary: { [key: string]: T } = {}

			const onChildAddedOrChanged = (snapshot: firebase.database.DataSnapshot) => {
				if (snapshot.key) {
					dictionary[snapshot.key] = snapshot.val()
					setData({
						loading: false,
						items: DatabaseService.firebaseKeyValuesToArray(dictionary),
					})
				}
			}
			const onChildRemoved = (snapshot: firebase.database.DataSnapshot) => {
				if (snapshot.key && snapshot.key in dictionary) {
					delete dictionary[snapshot.key]
					setData({
						loading: false,
						items: DatabaseService.firebaseKeyValuesToArray(dictionary),
					})
				}
			}

			this.ref.on('child_added', onChildAddedOrChanged)
			this.ref.on('child_changed', onChildAddedOrChanged)
			this.ref.on('child_removed', onChildRemoved)
			return () => {
				this.ref.off('child_added', onChildAddedOrChanged)
				this.ref.off('child_changed', onChildAddedOrChanged)
				this.ref.off('child_removed', onChildRemoved)
			}
		}, [])

		return data
	}

	async exists(id: string): Promise<boolean> {
		const result = await this.ref.child(id).once('value')
		return result.exists()
	}

	async add(data: T): Promise<DbListItem<T>> {
		const result = await this.ref.push(data)
		return {
			id: result.key as string,
			data,
		}
	}

	set(id: string, data: T): Promise<void> {
		return this.ref.child(id).set(data)
	}

	useSet(id: string): [DbCommandResult, (data: T) => Promise<void>] {
		const [result, setResult] = useState<DbCommandResult>({ loading: false })

		const set = (data: T) => {
			setResult({ loading: true, success: undefined })
			return this.set(id, data)
				.then(() => setResult({ loading: false, success: true }))
				.catch(() => setResult({ loading: false, success: false }))
		}

		return [result, set]
	}
}
