import React from 'react'
import styled, { css } from 'styled-components'
import { Card, Row, Col, Form } from 'react-bootstrap'

export interface VersionMismatchProps {
	clientVersion: string
	latestVersion: string
	className?: string
}

const Component = ({ className, clientVersion, latestVersion }: VersionMismatchProps) => {
	return (
		<div className={className}>
			<Card className='content'>
				<Card.Header as='h5' className='bg-danger text-light'>
					Version out of date!
				</Card.Header>
				<Card.Body>
					<Card.Text>
						Your browser is caching an older version of this application. Please do a
						hard refresh (aka{' '}
						<a
							className='text-danger'
							rel='noopener noreferrer'
							href='https://refreshyourcache.com/en/cache/'
							target='_blank'
						>
							Force Refresh
						</a>
						) or{' '}
						<a
							className='text-danger'
							rel='noopener noreferrer'
							href='https://refreshyourcache.com/en/home/'
							target='_blank'
						>
							clear your cache
						</a>
						.
					</Card.Text>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Your Version</Form.Label>
								<div>{clientVersion}</div>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Latest Version</Form.Label>
								<div>{latestVersion}</div>
							</Form.Group>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}

export const VersionMismatch = styled(Component)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 10000;
	opacity: 0;
	pointer-events: none;
	transition: opacity ease-in-out 0.25s;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.latestVersion !== props.clientVersion &&
		css`
			opacity: 1;
			pointer-events: all;
		`}

	.content {
		max-width: 500px;
	}

	label {
		font-weight: bold;
	}
`
