import { Player } from './player'
import { GameName } from './game'
import { FirebaseArray } from './database'

export interface LobbyJson {
	date: number
	name: string
	host: LobbyHost
	maxPlayers: number
	players: FirebaseArray<LobbyPlayer>
	game: GameName
	status: LobbyStatus
}

export class Lobby {
	date: Date
	name: string
	host: LobbyHost
	maxPlayers: number
	players: Array<LobbyPlayer>
	game: GameName
	status: LobbyStatus

	constructor(lobby: LobbyJson) {
		this.date = new Date(lobby.date)
		this.name = lobby.name
		this.host = lobby.host
		this.maxPlayers = lobby.maxPlayers
		this.players = Object.values(lobby.players || {})
		this.game = lobby.game
		this.status = lobby.status
	}

	addPlayer(player: LobbyPlayer) {
		this.players.push(player)
	}

	hasPlayer(userId: string) {
		return this.players.findIndex((p) => p.userId === userId) >= 0
	}

	removePlayer(userId: string) {
		const index = this.players.findIndex((p) => p.userId === userId)
		if (index >= 0) {
			this.players.splice(index, 1)
		}
	}

	toJson = (): LobbyJson => {
		const players: FirebaseArray<LobbyPlayer> = {}
		this.players.forEach((p, index) => (players[index] = p))

		return {
			players,
			date: this.date.getTime(),
			name: this.name,
			host: this.host,
			maxPlayers: this.maxPlayers,
			game: this.game,
			status: this.status,
		}
	}
}

export type LobbyStatus = 'Waiting for Players' | 'Started'

export interface LobbyPlayer extends Player {}

export interface LobbyHost extends Player {}
