import React from 'react'
import styled from 'styled-components'

import { useGame } from '../game-context'
import { Card } from './Card'
import { ScumRoundPlay } from '../view-models'
import { GamePlayer } from 'games/game-player'
import { Sound } from 'components'

const WaitingForPlayer = styled.div`
	position: fixed;
	top: calc(50% - 200px);
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	text-align: center;
	font-size: 32px;
`

function transformRotate(random: number) {
	const rotate = Math.floor(random * 41) - 20
	return `rotate(${rotate}deg)`
}

const Component = (props: { className?: string }) => {
	const { round, players, currentTurnPlayer, yourPlayer } = useGame()
	const { plays } = round

	if (!plays || !plays.length) {
		return (
			<WaitingForPlayer>
				Waiting for <span className='text-warning'>{currentTurnPlayer.name}</span> to start
				round.
			</WaitingForPlayer>
		)
	}

	const lastPlay = plays.last() as ScumRoundPlay
	const player = players.find((p) => p.userId === lastPlay.userId) as GamePlayer

	return (
		<div className={props.className}>
			<div className='plays'>
				{plays.map((play) => (
					<div
						key={play.id}
						className='played-cards'
						style={{ transform: transformRotate(play.random) }}
					>
						<div className='animate__animated animate__rotateIn animate__faster'>
							{play.cards.map((card, index) => (
								<Sound
									key={card.id}
									condition={yourPlayer.settings.enableSounds}
									audio='cards/single-card-deal.wav'
									delay={index * 100}
								>
									<Card card={card} />
								</Sound>
							))}
						</div>
					</div>
				))}
			</div>
			<div className='player'>
				Played by <span className='text-warning'>{player.name}</span>
			</div>
		</div>
	)
}

export const LastPlayed = styled(Component)`
	position: fixed;
	top: calc(50% - 300px);
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	pointer-events: none;

	.plays {
		position: relative;

		.played-cards {
			position: absolute;
			text-align: center;
			width: 100%;

			${Card} {
				display: inline-block;
				margin-left: -96px;
				width: 160px;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}

	.player {
		margin-top: -100px;
		color: white;
		text-align: center;
		font-size: 32px;
	}

	@media (max-height: 800px) {
		top: calc(50% - 200px);

		.plays {
			.played-cards {
				${Card} {
					width: 100px;
					margin-left: -70px;
				}
			}
		}
	}

	@media (max-height: 600px) {
		.player {
			margin-top: -60px;
			font-size: 24px;
		}
	}
`
