import { FormikHelper } from './FormikForm'
import { createContext } from '../../utils'

export interface FormikFormContextValue {
	/** The object returned from the 'useFormik()' hook */
	formik: FormikHelper
	/** Whether a Formik component should be disabled or not. */
	disabled?: boolean
}

export const [useFormikForm, FormikFormProvider] = createContext<FormikFormContextValue>()
