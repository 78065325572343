import { PlayerCard } from './player-card'
import { CardFace } from 'games/standard-card-deck'

export class CardGroup {
	constructor(
		public cards: Array<PlayerCard>,
		public face: CardFace,
		public value: number,
		public playable?: boolean,
	) {}
}
