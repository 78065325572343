import { useGame } from '../game-context'
import { useEffect } from 'react'

/** Checks to see if you haven't been assigned a new position if you have no cards during a round. If you need a position, then claim one. */
export function useCheckNextOrdinal() {
	const { round, status, yourPlayer: you } = useGame()

	const youHaveNoNextOrdinal = you.nextOrdinal === undefined
	const youHaveNoCards = you.cards.length === 0
	const isPlayingRounds = status === 'Playing Rounds'

	useEffect(() => {
		if (youHaveNoNextOrdinal && youHaveNoCards && isPlayingRounds) {
			round.manager.claimNextOrdinal().then((result) => {
				if (!result.success) {
					alert(result.errors?.join('\n'))
				}
			})
		}
	}, [youHaveNoNextOrdinal, youHaveNoCards, isPlayingRounds, round.manager])
}
