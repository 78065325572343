import React from 'react'
import styled from 'styled-components'
import { PlayerCard } from '../view-models'
import { palette } from 'assets/palette'

const { card } = palette
const { minPixels, maxPixels, viewWidth } = card.clampWidth

export interface CardProps {
	card: PlayerCard
	faceDown?: boolean
}

const Component = ({ card, faceDown, ...props }: CardProps & JSX.IntrinsicElements['div']) => {
	return <div {...props}></div>
}

export const Card = styled(Component)`
	position: relative;
	display: block;
	width: clamp(${minPixels}px, ${viewWidth}vw, ${maxPixels}px);
	background: url(${(props) => (props.faceDown ? props.card.backImageUrl : props.card.imageUrl)})
		no-repeat;
	border-radius: ${card.borderRadius};
	background-size: cover;
	box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	border: 1px solid #bcbec0;
	overflow: hidden;

	&::before {
		content: '';
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
		padding-top: 152%;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
`
