import React, { useState } from 'react'
import { Row, Card, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { lobbyService } from '../services'
import { DisplayDate, FlexInfo, LobbyModal, LobbyModalFormModel } from '../components'
import { Lobby, AppUser } from '../models'
import { useAppUser, useUser } from '../contexts'

export const HomePage = () => {
	const history = useHistory()
	const result = lobbyService.useGetLobbies()
	const { id: userId } = useUser() as AppUser

	const joinLobby = (id: string, lobby: Lobby) => {
		if (lobby.players.length < lobby.maxPlayers && lobby.status === 'Waiting for Players') {
			history.push(`/lobby/${id}`)
		}
		if (lobby.status === 'Started' && lobby.players.find((p) => p.userId === userId)) {
			history.push(`/game/${id}`)
		}
	}

	return (
		<>
			<div className='d-flex'>
				<h1 className='display-4'>Game Lobbies</h1>
				<CreateLobbyModal />
			</div>
			<hr className='my-4' />
			<Row>
				{result.items.map(({ id, data: lobby }) => (
					<Col key={id} md={4}>
						<Card>
							<Card.Body>
								<Card.Title className='d-flex'>
									{lobby.name}{' '}
									<span className='ml-auto'>
										{Object.keys(lobby.players).length}/{lobby.maxPlayers}
									</span>
								</Card.Title>
								<FlexInfo label='Game'>{lobby.game}</FlexInfo>
								<FlexInfo label='Host'>{lobby.host.name}</FlexInfo>
								<FlexInfo label='Created'>
									<DisplayDate value={lobby.date} />
								</FlexInfo>
								{lobby.status === 'Waiting for Players' && (
									<Button
										className='mt-3'
										disabled={
											!lobby.players.find((p) => p.userId === userId) &&
											lobby.players.length >= lobby.maxPlayers
										}
										variant='primary'
										onClick={() => joinLobby(id, lobby)}
									>
										Join Game
									</Button>
								)}
								{lobby.status === 'Started' &&
									lobby.players.find((p) => p.userId === userId) && (
										<Button
											className='mt-3'
											variant='primary'
											onClick={() => joinLobby(id, lobby)}
										>
											Reconnect to Game
										</Button>
									)}
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</>
	)
}

const CreateLobbyModal = () => {
	const { id } = useAppUser()
	const history = useHistory()
	const [show, setShow] = useState(false)

	const onSave = async (form: LobbyModalFormModel) => {
		const result = await lobbyService.create(form, id)

		if (result.success && result.payload) {
			history.push(`/lobby/${result.payload.id}`)
		}

		return result
	}

	return (
		<>
			<Button
				className='ml-auto align-self-center'
				variant='primary'
				onClick={() => setShow(true)}
			>
				Create Lobby
			</Button>
			<LobbyModal show={show} onHide={() => setShow(false)} isCreating onSave={onSave} />
		</>
	)
}
