/**
 * Static class with helper functions for creating error messages with Yup JS validation
 */
export class ErrorMessage {
	/**
	 * Required field validation message
	 * @param friendlyFieldName The user friendly field name to display
	 */
	static required(friendlyFieldName: string) {
		return `${friendlyFieldName} is required`
	}

	/**
	 * Max character length field validation message
	 * @param friendlyFieldName The user friendly field name to display
	 */
	static max(friendlyFieldName: string) {
		return (params: { max: number }) =>
			`${friendlyFieldName} must be at most ${params.max} characters`
	}

	/**
	 * Email validation message
	 * @param friendlyFieldName The user friendly field name to display
	 */
	static email(friendlyFieldName: string) {
		return `${friendlyFieldName} must be a valid email`
	}

	static minNumber(friendlyFieldName: string) {
		return (params: { min: number }) =>
			`${friendlyFieldName} must be greater than or equal to ${params.min}`
	}

	static maxNumber(friendlyFieldName: string) {
		return (params: { max: number }) =>
			`${friendlyFieldName} must be less than or equal to ${params.max}`
	}
}
