import React from 'react'

import { useFormikForm } from './formik-form.context'
import { Form } from 'react-bootstrap'

export interface FormikSwitchProps {
	/** The form field name (should be the same name as registered with 'useFormik()') */
	name: string
	/** The text label for the field */
	label: string
	readOnly?: boolean
	helperText?: string
}

/**
 * A Wrapper TextField/FormGroup component that disables itself if it's parent FormikForm is disabled and sets up errors, events, etc
 * @param props
 */
export const FormikSwitch = ({ name, helperText, ...control }: FormikSwitchProps) => {
	const { formik, disabled } = useFormikForm()

	return (
		<Form.Group controlId={name}>
			<Form.Check
				disabled={disabled}
				type='switch'
				checked={formik.values[name]}
				onChange={() => formik.setFieldValue(name, !formik.values[name])}
				{...control}
			/>
			{helperText && <Form.Text className='text-muted'>{helperText}</Form.Text>}
			<Form.Control.Feedback type='invalid'>
				{formik.touched[name] && formik.errors[name]}
			</Form.Control.Feedback>
		</Form.Group>
	)
}
