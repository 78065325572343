import React, { useState, useEffect } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { Modal, Button } from 'react-bootstrap'

interface ConfirmModalProps {
	message: string
	onTrue: () => void
	onFalse: () => void
}

const ConfirmModal = ({ message, onTrue, onFalse }: ConfirmModalProps) => {
	const [show, setShow] = useState(false)

	useEffect(() => {
		setTimeout(() => setShow(true), 50)
	}, [])

	const handleFalse = () => {
		setShow(false)
		setTimeout(() => onFalse(), 300)
	}

	const handleTrue = () => {
		setShow(false)
		setTimeout(() => onTrue(), 300)
	}

	return (
		<Modal show={show} onHide={handleFalse}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmation</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleFalse}>
					Cancel
				</Button>
				<Button variant='primary' onClick={handleTrue}>
					Ok
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export function confirmation(message: string): Promise<boolean> {
	return new Promise<boolean>((resolve) => {
		const container = document.createElement('div')
		document.body.appendChild(container)

		const handle = (value: boolean) => {
			document.body.removeChild(container)
			unmountComponentAtNode(container)
			resolve(value)
		}

		render(
			<ConfirmModal
				message={message}
				onTrue={() => handle(true)}
				onFalse={() => handle(false)}
			/>,
			container,
		)
	})
}
