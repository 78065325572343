import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { useGame } from '../game-context'
import { GameManager } from '../game-manager'
import { PlayerCard } from '../view-models'
import { Card } from './Card'
import { Settings } from './Settings'
import { CardsExchanged } from './CardsExchanged'
import { RandomTada } from './RandomTada'

export interface PlayersStatusProps {
	className?: string
}

function ordinalToName(ordinal: number, totalPlayers: number) {
	if (ordinal === 0) {
		return 'King'
	}
	if (ordinal === totalPlayers - 1) {
		return 'Scum'
	}
	if (totalPlayers >= 5) {
		if (ordinal === 1) {
			return 'Queen'
		}
		if (ordinal === totalPlayers - 2) {
			return 'Peasant'
		}
	}
	return 'Villager'
}

function outNumber(ordinal: number) {
	switch (ordinal + 1) {
		case 1:
			return '1st'
		case 2:
			return '2nd'
		case 3:
			return '3rd'
		default:
			return `${ordinal + 1}th`
	}
}

const PlayersStatusComponent = ({ className }: PlayersStatusProps) => {
	const { round, players } = useGame()
	const { playSize } = round

	const card = new PlayerCard('fake', { face: 'Ace', suit: 'Spades', color: 'Blue' })

	return (
		<div className={className}>
			<ul className='players'>
				{players.map((p) => (
					<li
						className={classNames({
							online: p.connected,
							active: p.isYourTurn,
							passed: p.passed,
						})}
						key={p.userId}
					>
						<Card card={card} faceDown /> {p.cards.length} - {p.name}
						{p.nextOrdinal !== undefined && (
							<RandomTada ordinal={p.nextOrdinal}>
								{' '}
								[{outNumber(p.nextOrdinal)} -{' '}
								{ordinalToName(p.nextOrdinal, players.length)}]
							</RandomTada>
						)}
					</li>
				))}
			</ul>
			<div className='play-size'>
				<Settings />
				{playSize && (
					<span className='ml-auto the-size'>{GameManager.toPlaySizeName(playSize)}</span>
				)}
			</div>
			<CardsExchanged />
		</div>
	)
}

export const PlayersStatus = styled(PlayersStatusComponent)`
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;

	.players {
		list-style: none;
		margin: 0;
		padding: 10px;

		li {
			display: flex;
			align-items: center;
			margin-bottom: 5px;

			&:before {
				content: '';
				display: inline-block;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: red;
				margin-right: 10px;
				transition: background-color ease-in-out 0.15s;
			}

			&.online {
				&:before {
					background-color: green;
				}
			}

			&.active {
				font-weight: bold;
			}

			&.passed {
				opacity: 0.5;
			}

			${Card} {
				margin: 0 10px;
				width: 20px;
				display: inline-block;
				box-shadow: none;
			}
		}
	}

	.play-size {
		padding: 5px;
		border-top: 1px solid #ccc;
		display: flex;
		align-items: center;

		.the-size {
			font-size: 14px;
		}

		span {
			padding: 0.375rem 0.75rem;
		}
	}

	.${Settings} {
		border-top: 1px solid #ccc;
	}
`
