import 'utils/array-utils'

export type CardFace =
	| 'Ace'
	| '2'
	| '3'
	| '4'
	| '5'
	| '6'
	| '7'
	| '8'
	| '9'
	| '10'
	| 'Jack'
	| 'Queen'
	| 'King'
export type CardSuit = 'Diamonds' | 'Clubs' | 'Hearts' | 'Spades'
export type CardColor = 'Blue' | 'Gray' | 'Green' | 'Purple' | 'Red' | 'Yellow'

export interface CardJson {
	face: CardFace
	suit: CardSuit
	color: CardColor
}

const CARD_FACES: Array<CardFace> = [
	'Ace',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'Jack',
	'Queen',
	'King',
]
const CARD_SUITS: Array<CardSuit> = ['Diamonds', 'Clubs', 'Hearts', 'Spades']

export class StandardCardDeck {
	static newDeck(...colors: CardColor[]): StandardCardDeck {
		if (!colors || !colors.length) {
			colors = ['Blue']
		}
		return new StandardCardDeck(colors)
	}

	static newShuffledDeck(...colors: CardColor[]): StandardCardDeck {
		const deck = StandardCardDeck.newDeck(...colors)
		deck.shuffle()
		return deck
	}

	private cards: Array<CardJson>

	private constructor(public colors: CardColor[]) {
		this.cards = []
		for (const color of colors) {
			for (const face of CARD_FACES) {
				for (const suit of CARD_SUITS) {
					this.cards.push({
						face,
						suit,
						color,
					})
				}
			}
		}
	}

	shuffle(times = 10): void {
		for (let i = 0; i < times; i++) {
			this.cards = this.cards.shuffle()
		}
	}

	draw(): CardJson | undefined {
		return this.cards.shift()
	}
}
