import { CommandResult, Lobby } from 'models'
import { LobbyService, lobbyService, GameService } from 'services'
import { ScumJson, ScumPlayerJson, ScumRoundJson } from 'models/games/scum'
import { StandardCardDeck, CardFace, CardJson, CardColor } from 'games/standard-card-deck'
import { ArrayUtils } from 'utils'
import { PlayerCard } from './view-models'

export class GameManager {
	constructor(private lobbyService: LobbyService, private gameService: GameService<ScumJson>) {}

	async create(lobbyId: string): Promise<CommandResult<ScumJson>> {
		const lobby = await this.lobbyService.get(lobbyId)

		if (!lobby) {
			return CommandResult.failure<ScumJson>(`Lobby '${lobbyId}' could not be found.`)
		}

		const players = GameManager.createScumPlayers(lobby).orderBy((p) => p.ordinal)

		const game = GameManager.createNewGame(players, 1)

		await this.gameService.create(lobbyId, game)

		return CommandResult.success(game)
	}

	static createNewGame(
		players: Array<ScumPlayerJson>,
		gameCount: number,
		startingPlayerUserId?: string,
		lastRound?: ScumRoundJson,
	): ScumJson {
		GameManager.dealCardsToPlayers(players)
		if (startingPlayerUserId) {
			GameManager.swapCards(players)
		}

		const game: ScumJson = {
			players: players.toFirebaseArray((p) => p.userId),
			round: {
				plays: {},
				status: 'Start',
			},
			gameCount,
			lastRound,
			currentTurnUserId:
				startingPlayerUserId || GameManager.findStartingPlayer(players).userId,
			status: 'Playing Rounds', //TODO: we're going to JUMP straight into the next game and auto exchange until programmed
		}
		if (!game.lastRound) {
			delete game.lastRound
		}

		return game
	}

	static swapCards(players: Array<ScumPlayerJson>) {
		players = players.orderBy((p) => p.ordinal)
		const king = players[0]
		const scum = players.last() as ScumPlayerJson

		if (players.length >= 5) {
			GameManager.swapCardsWithPlayers(king, scum, 2)
			const queen = players[1]
			const peasant = players[players.length - 2]
			GameManager.swapCardsWithPlayers(queen, peasant, 1)
		} else {
			GameManager.swapCardsWithPlayers(king, scum, 1)
		}
	}

	static swapCardsWithPlayers(
		king: ScumPlayerJson,
		scum: ScumPlayerJson,
		numberToExchange: number,
	) {
		const kingCards = ArrayUtils.mapFromFirebaseArray(
			king.cards,
			(id, item) => new PlayerCard(id, item),
		).orderBy((p) => p.value)
		const scumCards = ArrayUtils.mapFromFirebaseArray(
			scum.cards,
			(id, item) => new PlayerCard(id, item),
		).orderBy((p) => p.value)

		const lowCards = this.removeLowestSingles(kingCards, numberToExchange)
		const highCards = scumCards.splice(scumCards.length - numberToExchange, numberToExchange)

		kingCards.push(...highCards)
		scumCards.push(...lowCards)

		king.cards = kingCards.toFirebaseArray((p) => p.id)
		king.cardsGiven = {
			cards: lowCards.toFirebaseArray((p) => p.id),
			userId: scum.userId,
		}
		scum.cardsReceived = {
			cards: { ...king.cardsGiven.cards },
			userId: king.userId,
		}

		scum.cards = scumCards.toFirebaseArray((p) => p.id)
		scum.cardsGiven = {
			cards: highCards.toFirebaseArray((p) => p.id),
			userId: king.userId,
		}
		king.cardsReceived = {
			cards: { ...scum.cardsGiven.cards },
			userId: scum.userId,
		}
	}

	static removeLowestSingles(
		cards: Array<PlayerCard>,
		numberToRemove: number = 1,
	): Array<PlayerCard> {
		//map cards by value (ignore duplicates)
		const cardMap: { [value: number]: PlayerCard } = {}
		for (const card of cards) {
			if (!cardMap[card.value] && cards.count((p) => p.value === card.value) === 1) {
				cardMap[card.value] = card
			}
		}
		//get array of singleCards ordered by smallest to largest
		const singleCards = Object.values(cardMap).orderBy((p) => p.value)

		//if there are more to remove than found, just remove the bottom two
		if (numberToRemove > singleCards.length) {
			return cards.splice(0, numberToRemove)
		}

		//if we have enough singles to remove, remove em
		const removedCards = singleCards.splice(0, numberToRemove)

		// remove them from original array
		for (const card of removedCards) {
			const index = cards.indexOf(card)
			if (index >= 0) {
				cards.splice(index, 1)
			}
		}

		return removedCards
	}

	static createScumPlayers(lobby: Lobby): Array<ScumPlayerJson> {
		const players: Array<ScumPlayerJson> = []
		for (let i = 0; i < lobby.players.length; i++) {
			const { userId, name } = lobby.players[i]
			players.push({
				name,
				userId,
				ordinal: i,
				passed: false,
				cards: {},
				settings: {
					enableAutoPass: true,
					enableBreakupMultipleConfirmation: true,
					enableSounds: true,
				},
			})
		}
		return players
	}

	static dealCardsToPlayers(players: Array<ScumPlayerJson>) {
		const colors: Array<CardColor> = ['Blue']
		if (players.length >= 6) {
			colors.push('Red')
		}
		const deck = StandardCardDeck.newShuffledDeck(...colors)

		let count = 0
		while (true) {
			const card = deck.draw()
			if (!card) {
				break
			}
			players[count % players.length].cards[GameManager.toCardId(card)] = card
			count++
		}
	}

	static toCardId(card: CardJson): string {
		return `${card.color}-${card.suit}-${card.face}`
	}

	static findPlayersWithTheMostOfCard(
		players: Array<ScumPlayerJson>,
		face: CardFace,
	): Array<ScumPlayerJson> {
		let count = 0
		const playersWithMost: Array<ScumPlayerJson> = []
		for (const player of players) {
			const cards = ArrayUtils.fromFirebaseArray(player.cards)
			const cardCount = cards.count((p) => p.face === face)
			if (cardCount > count) {
				playersWithMost.clear()
				playersWithMost.push(player)
				count = cardCount
			} else if (cardCount === count) {
				playersWithMost.push(player)
			}
		}
		return playersWithMost
	}

	static findStartingPlayer(players: Array<ScumPlayerJson>): ScumPlayerJson {
		const faceOrder: Array<CardFace> = [
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'10',
			'Jack',
			'Queen',
			'King',
			'Ace',
		]
		let checkPlayers: Array<ScumPlayerJson> = [...players]
		for (const face of faceOrder) {
			const newCheckPlayers = GameManager.findPlayersWithTheMostOfCard(checkPlayers, face)
			if (newCheckPlayers.length === 1) {
				return newCheckPlayers[0]
			}
			checkPlayers = newCheckPlayers
		}
		// if for some reason we can't find the first, just pick anyone
		return players[0]
	}

	static toPlaySizeName(playSize: number): string {
		switch (playSize) {
			case 1:
				return 'Singles'
			case 2:
				return 'Doubles'
			case 3:
				return 'Triples'
			case 4:
				return 'Quadruples'
			default:
				return `${playSize} of a kind`
		}
	}
}

export const gameManager = new GameManager(lobbyService, GameService.construct<ScumJson>())
