import { UserInfo } from './auth.service'
import { UserJson } from '../models'
import { firebaseService } from './firebase.service'
import { DatabaseService } from './database.service'
import { DbResult, FirebaseDbService } from './database-models'

export class UserService {
	constructor(private users: FirebaseDbService<UserJson>) {}

	async updateUserFromInfo(user: UserInfo) {
		if (user.authenticated) {
			if (!(await this.users.exists(user.id))) {
				await this.users.set(user.id, {
					name: user.name || 'Unknown User',
				})
				return {
					unknownUser: !user.name || user.name === 'Unknown User',
				}
			}
			return {
				unknownUser: false,
			}
		}
		return {
			unknownUser: false,
		}
	}

	useUpdate(id: string) {
		return this.users.useUpdate(id)
	}

	useGet(id: string, once?: boolean): DbResult<UserJson> {
		return this.users.useGet(id, once)
	}

	get(id: string) {
		return this.users.get(id)
	}
}

export const userService = new UserService(new DatabaseService<UserJson>(firebaseService, 'users'))
