import React, { useState, useEffect } from 'react'
import { useGame } from '../game-context'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Card } from './Card'
import { ScumPlayer } from '../view-models'

const Component = (props: { className?: string }) => {
	const { yourPlayer: you, gameCount } = useGame()
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (you.hasExchangedCards) {
			setShow(true)
		}
	}, [you.hasExchangedCards, gameCount])

	if (!you.hasExchangedCards) {
		return <></>
	}

	return (
		<div className={props.className}>
			<Button size='sm' variant='link' onClick={() => setShow(true)}>
				<i className='fas fa-exchange-alt'></i> Show Exchange
			</Button>
			<Modal show={show} onHide={() => setShow(false)}>
				<StyledModalContent you={you} />
			</Modal>
		</div>
	)
}

const ModalContent = ({ className, you }: { className?: string; you: ScumPlayer }) => {
	if (!you || !you.hasExchangedCards) {
		return <></>
	}

	return (
		<div className={className}>
			<Modal.Header closeButton>
				<Modal.Title>
					Cards exchanged with{' '}
					<span className='text-primary'>{you.cardsGiven?.player.name}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md={6}>
						<h4 className='text-center'>You gave</h4>
						<div className='cards'>
							{you.cardsGiven?.cards
								.orderBy((p) => p.value)
								.map((card) => (
									<Card key={card.id} card={card} />
								))}
						</div>
					</Col>
					<Col md={6}>
						<h4 className='text-center'>You received</h4>
						<div className='cards'>
							{you.cardsReceived?.cards
								.orderBy((p) => p.value)
								.map((card) => (
									<Card key={card.id} card={card} />
								))}
						</div>
					</Col>
				</Row>
			</Modal.Body>
		</div>
	)
}

const StyledModalContent = styled(ModalContent)`
	.cards {
		display: flex;
		justify-content: center;
		padding: 10px 0;

		${Card} {
			margin: 0 5px;
		}
	}
`

export const CardsExchanged = styled(Component)`
	padding: 0 5px 5px 5px;

	.btn-link {
		text-decoration: none;
	}
`
