import React from 'react'
import styled, { css } from 'styled-components'
import { palette } from 'assets/palette'

export interface SplashProps {
	show?: boolean
	className?: string
}

//@ts-ignore
const Component = ({ className, show }: SplashProps) => {
	return (
		<div className={className}>
			<div className='content'>
				<div className='logo'></div>
				<div className='title'>
					<div>Delfonzo</div>
					<div>Games</div>
				</div>
			</div>
		</div>
	)
}

export const Splash = styled(Component)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${palette.colors.primary};
	z-index: 10000;
	opacity: 0;
	pointer-events: none;
	transition: opacity ease-in-out 0.25s;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.show &&
		css`
			opacity: 1;
			pointer-events: all;
		`}

	.content {
		color: white;
		display: flex;
		align-items: center;

		.title {
			position: relative;
			top: -3px;
			margin-left: 10px;
			font-size: 32px;
			line-height: 1;

			div:first-child {
				transform: translateX(8px);
			}

			div:last-child {
				font-size: 48px;
				line-height: 40px;
			}
		}

		.logo {
			position: relative;
			display: inline-block;
			width: 60px;
			height: 60px;
			border-radius: 10px;
			background-color: white;
			padding: 5px;

			&::after {
				content: '';
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				background: url(/images/logo.png) no-repeat center center;
				background-size: contain;
			}
		}
	}
`
